* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}


body {
    background: var(--color-bg);
    
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {

    /* ff 3.6+ */
    background:-moz-radial-gradient(ellipse at 50% 20%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 91%, rgba(4, 12, 24, 0) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(ellipse at 50% 20%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 91%, rgba(4, 12, 24, 0) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(ellipse at 50% 20%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 91%, rgba(4, 12, 24, 0) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(ellipse at 50% 20%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 91%, rgba(4, 12, 24, 0) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(ellipse at 50% 20%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 91%, rgba(4, 12, 24, 0) 100%);

}
            
.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
  }
  
  .section__margin {
    margin: 4rem 12rem;
  }

  .skills__margin {
    margin: 0 auto;
    max-width: 1000px;
  }

  

  @media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
    .skills__margin {
        margin: 2rem 2rem;
        
      }
  }
  
  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
    .skills__margin {
        margin: 2rem 2rem;
        
      }
  }